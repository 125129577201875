import { Button, Card, Icon, P, useScreenClass } from '@vp/swan'
import { openNewTabWithTracking } from '../../../helpers/openNewTabWithTracking'

interface ActionCardProps {
  imgSrc: string;
  imgAlt: string;
  title: string;
  description: string;
  linkText: string
  linkUrl: string
}
export const ActionCard = ({ imgSrc, imgAlt, title, description, linkText, linkUrl = '#' }:ActionCardProps) => {
  const screenClass = useScreenClass()
  const isMobile = screenClass === 'xs' || screenClass === 'sm'
  return (
    <Card skin='link' className='section5__card' evenHeight={!isMobile}>
      <img src={imgSrc} alt={imgAlt} width={325} height={325} />
      <P fontSize='large' fontWeight='bold'>{title}</P>
      <P fontSize='small'>{description}</P>
      <Button
        skin='link'
        fontWeight='bold'
        className='text-black'
        covering href={linkUrl} onClick={() =>
          openNewTabWithTracking(
            linkUrl,
            linkText,
            'Hero',
            true
          )}
      >{linkText} <Icon iconType='arrowRight' />
      </Button>

    </Card>
  )
}
