import { Breadcrumb, Breadcrumbs, Link } from '@vp/swan'

interface PageBreadcrumbsProps {
  breadcrumbs: any
}
export const PageBreadcrumbs = ({ breadcrumbs }:PageBreadcrumbsProps) => {
  return (
    <Breadcrumbs mb={{ xs: 10, md: 2 }}>
      <Breadcrumb>
        <Link href='/'>{breadcrumbs[0].name}</Link>
      </Breadcrumb>
      <Breadcrumb>
        <Link href='#' aria-current='page'>
          {breadcrumbs[0].description.description}
        </Link>
      </Breadcrumb>
      <Breadcrumb>
        <Link href='#' aria-current='page'>
          {breadcrumbs[0].valueText}
        </Link>
      </Breadcrumb>
    </Breadcrumbs>
  )
}
