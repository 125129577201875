import {
  FluidImage,
  Banner,
  BannerDescription,
  BannerImage,
  BannerImageContainer,
  BannerText,
  BannerTextContainer,
  BannerTitle,
  P,
  Hidden,
  Box,
  FlexBox,
  useScreenClass,
  H2,
  Button,
  Icon,
} from '@vp/swan'
import { SectionProps } from '../types'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { openNewTabWithTracking } from '../../helpers/openNewTabWithTracking'
import { YELP_BUSINESS_LINK } from '../../const'
import { Hightlights } from './hightlights'

gsap.registerPlugin(ScrollTrigger)

const Section2 = ({ content }: SectionProps) => {
  const screenClass = useScreenClass()

  let backgroundImg = 'https://cms.cloudinary.vpsvc.com/image/upload/v1743762942/digital/promotions/yelp-pdp/section2/bg-section-2-xl-2.webp'
  switch (screenClass) {
    case 'xs':
    case 'sm':
      backgroundImg = 'https://cms.cloudinary.vpsvc.com/image/upload/v1742997668/digital/promotions/yelp-pdp/section2/bg-section-2-sm.webp'
      break

    case 'md':
      backgroundImg = 'https://cms.cloudinary.vpsvc.com/image/upload/v1742997668/digital/promotions/yelp-pdp/section2/bg-section-2-md.webp'
      break

    case 'lg':
    case 'xl':
      backgroundImg = 'https://cms.cloudinary.vpsvc.com/image/upload/v1743762942/digital/promotions/yelp-pdp/section2/bg-section-2-xl-2.webp'
      break

    default:
      backgroundImg = 'https://cms.cloudinary.vpsvc.com/image/upload/v1743762942/digital/promotions/yelp-pdp/section2/bg-section-2-xl-2.webp'
  }

  return (
    <Box className='block--section2' mt={{ xs: 10, md: 9 }}>
      <Hidden xs>
        <Banner className='section2-banner' variant='full-width-image'>
          <BannerTextContainer style={{ placeSelf: 'end' }} className='banner-text-container'>
            <BannerText style={{ maxWidth: '450px' }} ml={0}>
              <BannerTitle>{content[0].title}</BannerTitle>
              <BannerDescription>
                <P>
                  With over 308 million reviews<span className='text-citation'>2</span>, Yelp is a local marketing tool that gets your brand in front of customers. The result? Get discovered, capture reviews, connect with customers, generate leads, and so much more.
                </P>
              </BannerDescription>
              <Button
                className='bg-primary text-primary-foreground'
                skin='primary'
                onClick={() =>
                  openNewTabWithTracking(
                    YELP_BUSINESS_LINK,
                    String(content[0].valueText),
                    'GrowOnline',
                    true
                  )}
                title='https://business.yelp.com'
              >
                Explore Yelp for business
                <Icon iconType='arrowRight' className='bg-black' />
              </Button>
            </BannerText>
          </BannerTextContainer>

          <BannerImageContainer>
            <BannerImage>
              <FluidImage
                src={backgroundImg}
                alt='section 2 background desktop'
                style={{ borderRadius: '16px' }}
              />
            </BannerImage>
          </BannerImageContainer>

        </Banner>
      </Hidden>

      <Hidden sm md lg xl>
        <FlexBox className='relative w-full' flexDirection='column'>

          <FluidImage
            src='https://cms.cloudinary.vpsvc.com/image/upload/v1742946152/digital/promotions/yelp-pdp/section2/bg-section-2-sm.png'
            alt='section 2 background mobile'
            className='bg-img'
          />

          <Box p={{ xs: 5, md: 6 }} className='bg-white section2-text-container text-center'>
            <H2>{content[0].title}</H2>
            <P m={0}>
              With over 308 million reviews<span className='text-citation'>2</span>, Yelp is a local marketing tool that gets your brand in front of customers. The result? Get discovered, capture reviews, connect with customers, generate leads, and so much more.
            </P>
            <Button
              mt={3}
              skin='primary'
              onClick={() =>
                openNewTabWithTracking(
                  YELP_BUSINESS_LINK,
                  String(content[0].valueText),
                  'GrowOnline',
                  true
                )}
              title='https://business.yelp.com'
            >
              Explore Yelp for business <Icon iconType='arrowRight' />
            </Button>
          </Box>
        </FlexBox>
      </Hidden>

      <Hightlights />

    </Box>
  )
}

export default Section2
