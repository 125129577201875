'use client'

import {
  Carousel,
  CarouselSlide,
  BasicResponsiveImage,
  FlexBox,
  FluidImage,
  useScreenClass,
  CarouselProps,
} from '@vp/swan'
import { useState, useEffect } from 'react'
import { stylesheet } from './carousel.scss'
import { useStyles } from '@vp/ubik-context'

const DEFAULT_CAROUSEL_INTERVAL = 7000

interface CarouselThumbnailProps extends CarouselProps {
  assets: {
    image: string;
    thumbnail: string;
  }[];
  autoScroll?: boolean;
  scrollInterval?: number;
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
}

export const CarouselThumbnail = ({
  assets,
  autoScroll = false,
  scrollInterval = DEFAULT_CAROUSEL_INTERVAL,
  objectFit = 'contain',
  ...props
}: CarouselThumbnailProps) => {
  useStyles(stylesheet)

  const screenClass = useScreenClass()
  const isMobile = screenClass === 'xs'

  const [defaultSlide, setDefaultSlide] = useState(0)
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)

  const startInterval = () => {
    const _intervalId = setInterval(() => {
      setDefaultSlide((prevSlide) =>
        prevSlide === assets.length - 1 ? 0 : prevSlide + 1
      )
    }, scrollInterval)

    setIntervalId(_intervalId)
  }

  const nextSlide = (index: number) => {
    setDefaultSlide(index)

    if (autoScroll) {
      if (intervalId !== null) {
        clearInterval(intervalId)
      }
      startInterval()
    }
  }

  useEffect(() => {
    if (autoScroll) startInterval()
  }, [])

  return (
    <>
      <Carousel className='carousel-container' {...props} arrows={false} currentSlide={defaultSlide}>
        {assets.map((asset, index) => {
          return (
            <CarouselSlide key={index}>
              <BasicResponsiveImage
                aspectRatio={1}
                src={asset.image}
                style={{
                  objectFit,
                }}
                alt='carousel-image'
              />
            </CarouselSlide>
          )
        })}
      </Carousel>
      <FlexBox
        gap={4}
        mt={5}
        ml={isMobile ? 0 : 6}
        justifyContent={isMobile ? 'center' : 'right'}
      >
        {assets.map((asset, index) => {
          return (
            <FluidImage
              className='carousel-thumbnail'
              key={index}
              src={asset.thumbnail}
              style={{
                border: defaultSlide === index ? '3px solid #000' : 'none',
              }}
              onClick={() => nextSlide(index)}
              alt='thumbnail'
            />
          )
        })}
      </FlexBox>
    </>
  )
}
