import { Card, CarouselSlide, P } from '@vp/swan'

interface TestimonialCarouselItemProps {
  text: string;
  author: string;
  textHeight?: string;
}
export const TestimonialCarouselItem = ({
  text,
  author,
  textHeight,
}: TestimonialCarouselItemProps) => {
  return (
    <CarouselSlide>
      <Card
        fullBleed
        overflow='hidden'
        py={6}
        px={7}
        bgc='accent'
        className='carousel-item'
      >
        <P
          fontSize='large'
          fontWeight='bold'
          style={{
            minHeight: textHeight,
          }}
        >
          {text}
        </P>
        <P mt={6}>{author}</P>
      </Card>
    </CarouselSlide>
  )
}
