import { Carousel, CarouselSlide, Column, GridContainer, Hidden, Row } from '@vp/swan'
import { ActionCard } from './action-card'
import { VISTA_BUSINESS_CARDS_LINK, VISTA_DESIGN_LINK, VISTA_SIGNS_POSTERS_PRODUCT_LINK } from '../../../const'

interface ActionCardsProps {
  locale: string
}
export const ActionCards = ({ locale }:ActionCardsProps) => {
  const cardsData = [
    {
      imgSrc: 'https://cms.cloudinary.vpsvc.com/image/upload/v1742998239/digital/promotions/yelp-pdp/section5/section5-img1.webp',
      imgAlt: 'Capture their attention',
      title: 'Capture their attention',
      description: 'Create signage they can’t miss at prices that won’t break your budget.',
      linkText: 'Explore custom signs',
      linkUrl: VISTA_SIGNS_POSTERS_PRODUCT_LINK(locale)
    },
    {
      imgSrc: 'https://cms.cloudinary.vpsvc.com/image/upload/v1742998242/digital/promotions/yelp-pdp/section5/section5-img2.webp',
      imgAlt: 'Make great first impressions',
      title: 'Make great first impressions',
      description: 'Time-saving, budget-thanking, easy-to-customize business cards? You\'re in good hands (literally).',
      linkText: 'Explore business cards',
      linkUrl: VISTA_BUSINESS_CARDS_LINK(locale)
    },
    {
      imgSrc: 'https://cms.cloudinary.vpsvc.com/image/upload/v1742998237/digital/promotions/yelp-pdp/section5/section5-img3.webp',
      imgAlt: 'Bring your brand to life',
      title: 'Bring your brand to life',
      description: 'Work with professional designers – from unique logos to custom marketing materials and beyond.',
      linkText: 'Explore design services',
      linkUrl: VISTA_DESIGN_LINK(locale)
    },
  ]

  return (
    <>
      <Hidden xs sm>
        <GridContainer my={10} gap={6} className='section5__cards'>
          <Row>
            {cardsData.map((card, index) => (
              <Column key={index} span={4}>
                <ActionCard key={index} {...card} />
              </Column>
            ))}
          </Row>
        </GridContainer>
      </Hidden>

      <Hidden md lg xl>
        <Carousel
          slidesToShow={{ xs: 1, sm: 2 }}
          gridGutters
          progressIndicator='dots'
          aria-label='A carousel with dots'
          accessibleTextForPrevious='Go to previous slide'
          accessibleTextForNext='Go to next slide'
          accessibleTextForDots={['Slide One', 'Slide Two', 'Slide 3']}
          accessibleTextForVisibleSlides='Slide {activeSlides} of {totalSlides}'
          aria-roledescription='carousel'
          mt={6}
        >
          {cardsData.map((card, index) => (
            <CarouselSlide key={index} aria-roledescription='slide'>
              <ActionCard {...card} />
            </CarouselSlide>
          ))}
        </Carousel>
      </Hidden>
    </>
  )
}
