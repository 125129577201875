import { trackCtaClick } from './YelpPdpPageAnalytics'

// Declare the global 'window' object
declare const window: any

export const openNewTabWithTracking = (
  path: string,
  label: string,
  section: string
): void => {
  trackCtaClick(label, section)
  if (typeof window !== 'undefined') window.open(path, '_blank')
}
