import {
  P,
  Box,
  Carousel,
  Button,
  FlexBox,
  Hidden,
  useScreenClass,
  H2,
  Icon,
} from '@vp/swan'
import { useEffect, useState } from 'react'
import { SectionProps, TestimonialItemProps } from '../types'
import { YELP_BUSINESS_LINK } from '../../const'
import { openNewTabWithTracking } from '../../helpers/openNewTabWithTracking'
import { TestimonialCarouselItem } from './testimonial-carousel-item'

const Section4 = ({ content, locale }: SectionProps) => {
  const screenClass = useScreenClass()
  const isMobile = screenClass === 'xs'
  const [textHeight, setTextHeight] = useState('78px')

  useEffect(() => {
    if (locale === 'fr-CA' && !isMobile) setTextHeight('165px')
    else if (locale === 'fr-CA' && isMobile) setTextHeight('110px')
    else setTextHeight('78px')
  }, [isMobile])

  const testimonials: TestimonialItemProps[] = []
  content
    .filter((item) => item.key?.toLowerCase().includes('testimonial'))
    .map((item) => {
      return testimonials.push({
        avatar: item.icons ? String(item.icons[0]?.secureUrl) : '',
        text: item.title,
        author: String(item.description),
      })
    })

  return (
    <Box mt={{ xs: 10, md: 11 }} className='block--section4'>
      <Box className='block--centered'>
        <FlexBox
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          gap={4}
          className='section4-top-section'
        >
          <center>
            <H2>{/* content[0].title */}Ready to get started?</H2>
            <P>{/* content[0].description */}You’ll be redirected to Yelp to create your free listing. It’s easy and only takes a few minutes to complete. Once you’ve added your listing, you can manage reviews, access local marketing tools including Yelp Ads and start connecting with new customers – all in one place. </P>
          </center>
          <Hidden xs sm>
            <Button
              skin='primary'
              onClick={() =>
                openNewTabWithTracking(
                  YELP_BUSINESS_LINK,
                  String(content[0].valueText),
                  'YelpJourney',
                  true
                )}
              title='https://business.yelp.com'
            >
              {content[0].valueText}
              <Icon iconType='arrowRight' />
            </Button>
          </Hidden>
        </FlexBox>

        <Carousel
          mt={{ xs: 4, sm: 6, md: 11 }}
          mx={{ sm: 8, md: 10, lg: 0 }}
          slidesToShow={{ xs: 1, sm: 2, lg: 3 }}
          gridGutters
          accessibleTextForPrevious='Go to previous slide'
          accessibleTextForNext='Go to next slide'
          progressIndicator='dots'
          // all accessible text should be localized
        >
          {testimonials.map((testimonial, i) => (
            <TestimonialCarouselItem
              key={i}
              text={testimonial.text}
              author={testimonial.author}
              textHeight={textHeight}
            />
          ))}
        </Carousel>

        <Hidden md lg xl>
          <center>
            <Button
              skin='primary'
              mt={6}
              onClick={() =>
                openNewTabWithTracking(
                  YELP_BUSINESS_LINK,
                  String(content[0].valueText),
                  'YelpJourney',
                  true
                )}
              title='https://business.yelp.com'
            >
              {content[0].valueText}
              <Icon iconType='arrowRight' />
            </Button>
          </center>
        </Hidden>
      </Box>
    </Box>
  )
}

export default Section4
