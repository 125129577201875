import {
  Button,
  FlexBox,
  Box,
  FluidImage,
  H1,
  P,
  Ul,
  Li,
  Hidden,
  Visible,
  Icon,
} from '@vp/swan'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { SectionProps } from '../types'
import { YELP_BUSINESS_LINK } from '../../const'
import { openNewTabWithTracking } from '../../helpers/openNewTabWithTracking'
import { CarouselThumbnail } from './carousel'

gsap.registerPlugin(ScrollTrigger)

const Section1 = ({ content }: SectionProps) => {
  /* const carouselContent = content.filter((feature) =>
    feature.key?.toLowerCase().includes('carousel')
  ) */
  const carouselAssets = [{
    image: 'https://cms.cloudinary.vpsvc.com/image/upload/v1743981080/digital/promotions/yelp-pdp/section1/carousel1/carousel-img1.webp',
    thumbnail: 'https://cms.cloudinary.vpsvc.com/image/upload/v1742812252/digital/promotions/yelp-pdp/section1/carousel1/carousel-img-1-thumbnail.png',
  },
  {
    image: 'https://cms.cloudinary.vpsvc.com/image/upload/v1743981122/digital/promotions/yelp-pdp/section1/carousel2/carousel-img2.webp',
    thumbnail: 'https://cms.cloudinary.vpsvc.com/image/upload/v1742812278/digital/promotions/yelp-pdp/section1/carousel2/carousel-img-2-thumbnail.png',
  },
  {
    image: 'https://cms.cloudinary.vpsvc.com/image/upload/v1743981149/digital/promotions/yelp-pdp/section1/carousel3/carousel-img3.webp',
    thumbnail: 'https://cms.cloudinary.vpsvc.com/image/upload/v1742812310/digital/promotions/yelp-pdp/section1/carousel3/carousel-img-3-thumbnail.png',
  }]

  const logo = 'https://cms.cloudinary.vpsvc.com/image/upload/f_auto/q_auto/v1736763726/digital/promotions/yelp-pdp/yelp-logo.svg'
  return (
    <>
      <FlexBox
        justifyContent='space-between'
        alignItems='center'
        style={{ width: '100%' }}
        className='block--section1'
      >
        <Box className='left-section-container'>
          <FluidImage src={logo} alt='Yelp Logo' className='logo' />

          <H1 my={4} className='section1-title'>
            {/* content[0].title */}
            Get discovered and grow with Yelp
          </H1>
          <P>{/* content[0].description */}
            We’ve partnered with Yelp so customers can find, fall in love with and support businesses – just like yours! Make more connections, manage reviews, get new leads and build your brand – all in the one place.
          </P>

          <Hidden xs>
            <P fontWeight='bold' fontSize='large' mt={4}>
              {/* content[1].title */}
              How can Yelp help you
            </P>
            <Ul skin='checkmark'>
              <Li>{/* content[1].valueText */}Claim and manage your Yelp listing for free</Li>
              <Li>{/* content[1].hoverText */}Get discovered and attract more customers</Li>
              <Li>{/* content[1].altText */}Get 7.6x more monthly page views<span className='text-citation'>1</span></Li>
              <Li>{/* content[1].altText */}Get 2.5x more leads and $300 worth of ad credits with Yelp Ads*</Li>
            </Ul>

            <Button
              skin='primary'
              mt={{ xs: 6, md: 7 }}
              onClick={() =>
                openNewTabWithTracking(
                  YELP_BUSINESS_LINK,
                  content[2].title,
                  'Hero',
                  true
                )}
              title='https://business.yelp.com'
            >
              {content[2].title}
              <Icon iconType='arrowRight' />
            </Button>
          </Hidden>
        </Box>

        <Box className='right-section-container'>
          <CarouselThumbnail assets={carouselAssets} objectFit='cover' autoScroll />
        </Box>

        <Visible xs className='left-section-container-sm'>
          <P fontWeight='bold' fontSize='large' mt={8}>
            {/* content[1].title */}
            How can Yelp help you
          </P>
          <Ul skin='checkmark'>
            <Li mt={2}>{/* content[1].valueText */}Claim and manage your Yelp listing for free</Li>
            <Li mt={{ xs: 2, md: 4 }}>{/* content[1].hoverText */}Get discovered and attract more customers</Li>
            <Li mt={{ xs: 2, md: 4 }}>{/* content[1].altText */}Get 7.6x more monthly page views1</Li>
            <Li mt={{ xs: 2, md: 4 }}>{/* content[1].altText */}Get 2.5x more leads and $300 worth of ad credits with Yelp Ads*</Li>
          </Ul>

          <Button
            skin='primary'
            mt={{ xs: 6, md: 7 }}
            onClick={() =>
              openNewTabWithTracking(
                YELP_BUSINESS_LINK,
                content[2].title,
                'Hero',
                true
              )}
            title='https://business.yelp.com'
          >
            {content[2].title}
            <Icon iconType='arrowRight' />
          </Button>
        </Visible>
      </FlexBox>
    </>
  )
}

export default Section1
